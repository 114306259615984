import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";
import ArticlePreviewList from "../components/article-preview-list";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Lottie from "react-lottie";
import animationDataFirst from "../components/lottie/33627-city-scape-city-building.json";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const defaultOptions = (data, loop) => ({
    loop: loop,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <h1 hidden>{site.title}</h1>
        <p>
          Eli Støa er professor i Bolig og Bosetting ved Fakultet for Arkitektur og billedkunst
          siden 2006. Eli underviser i boligprosjektering og boligteori på bachelor og masternivå.
          For tiden er hun leder for BOPILOT, et innovasjonsprosjekt i offentlig sektor. Prosjektet
          omhandler kommunens rolle som pådriver for alternative boligløsninger og er at samarbeid
          med Bergen og Trondheim kommune, SINTEF Community, Norske arkitekters landsforbund og
          Husbanken.
        </p>
        <p>
          Dette er hennes private side, og inneholder blant annet artikler skrevet for Adressa
          bolig.
        </p>
        <Lottie options={defaultOptions(animationDataFirst, true)} />
        {postNodes && (
          <ArticlePreviewList title="Artikler" nodes={postNodes} browseMoreHref="/artikler/" />
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
